<template>
    <div>
        <section
            class="pt-2"
            :class="
                cssVariables.attendee - header - bg - color != null
                    ? 'bg-attendee-header'
                    : 'bg-white'
            "
        >
            <div class="container p-6 md:p-12">
                <h1
                    class="title mg-title-header text-center text-dark font-bold"
                    :class="
                        layoutOptions.siteName == 'smacna' ? 'text-white' : ''
                    "
                >
                    Who's Here?
                </h1>
                <!-- <h1 class="title is-size-1  text-center has-dark-text ">Who’s Here?</h1> VMP -->
            </div>
        </section>
        <section
            class="section px-4 md:px-8"
            :class="
                cssVariables.attendee - content - bg - color != null
                    ? 'bg-attendee-content'
                    : 'bg-white'
            "
        >
            <div class="container mx-18">
                <div class="columns lg:px-20">
                    <div class="column is-two-fifths mb-8 lg:pr-10">
                        <h2
                            class="is-size-4 has-text-weight-bold flex justify-center md:justify-start"
                        >
                            <span class="underlined-header block w-full">{{
                                configurableLabels.attendeeListColumnHeader
                                    ? configurableLabels.attendeeListColumnHeader
                                    : "CONNECT WITH ATTENDEES"
                            }}</span>
                            <!-- <span class='underlined-header has-text-info'>CONNECT WITH ATTENDEES</span> vmp -->
                        </h2>

                        <div
                            v-if="isLoading"
                            class="flex flex-row justify-center my-10"
                        >
                            <Spinners />
                        </div>

                        <div>
                            <attendee-card
                                v-for="attendee in featuredAttendees"
                                :key="attendee.attendeeId"
                                :data="attendee"
                                layout="column"
                            />
                        </div>
                    </div>
                    <div class="column mb-8">
                        <h2
                            class="is-size-4 has-text-weight-bold flex items-center justify-center md:justify-start underlined-header"
                        >
                            LOOKING FOR SOMEONE IN PARTICULAR?
                        </h2>
                        <attendee-search-form
                            class="my-4"
                        ></attendee-search-form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import Vue from "vue"
import { getModule } from "vuex-module-decorators";
import { mapGetters } from "vuex";

import featuredAttendeeVuexModule from "@/store/vuex-modules/getFeaturedAttendees";

import Spinners from "@/components/utilities/Spinners.vue";
import AttendeeCard from "@/components/shared/AttendeeCard.vue";
import AttendeeSearchForm from "@/components/attendee-search/AttendeeSearchForm";


const featuredAttendeeStore = getModule(featuredAttendeeVuexModule);

export default Vue.extend ({
    data() {
        return {
            prev: "",
            session: "",
            bg: "",
            color: "",
            content: "",
            header: "",
            isLoading: true
        }
    },
    components: {
        AttendeeCard,
        AttendeeSearchForm,
        Spinners
    },

    computed: {
        ...mapGetters(["awsConfig"]),

        configurableLabels() {
            return this.$store.getters.attendeeLabels;
        },

        layoutOptions() {
            return this.$store.getters.layoutOptions;
        },

        cssVariables() {
            return this.$store.getters.cssVariables;
        },

        featuredAttendees() {
            if (featuredAttendeeStore.featuredAttendees) {
                return featuredAttendeeStore.featuredAttendees;
            } else {
                return [];
            }
        }
    }
})
</script>
